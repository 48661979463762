import { Injectable } from '@angular/core';
import { SearchRequest, SupportedCollections } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../cloud-functions.service';
import { BaseElasticSearchService } from './base-elastic-search-service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';
import { PolicyTransaction, PolicyTransactionKeys } from '@ag-common-lib/lib/models/domain/policy-transaction.model';
import { format } from 'date-fns';
import {
  AggregationsAggregationContainer,
  AggregationsCompositeAggregation,
  AggregationsHistogramAggregate,
  AggregationsSumAggregate,
  QueryDslQueryContainer,
} from '@elastic/elasticsearch/lib/api/types';
import { DxFilterOperators } from '@ag-common-lib/lib';
import { set } from 'lodash';
import { PolicyTransactionSummariesElasticSearchService } from './policy-transactions-summaries-elastic-search.service';
import { combineLatest, map } from 'rxjs';

export interface ProductionStatsPayload {
  year: number;
  pageSize?: number;
  agentId?: string;
  agencyIds?: string[];
  mgaId?: string;
}

@Injectable({ providedIn: 'root' })
export class PolicyTransactionElasticSearchService extends BaseElasticSearchService<PolicyTransaction> {
  private readonly histogramSummaries = [
    { summary: 'life_prem', field: PolicyTransactionKeys.lifePrem },
    { summary: 'targetTotal', field: PolicyTransactionKeys.targetPrem },
    { summary: 'excessTotal', field: PolicyTransactionKeys.excessPrem },
    { summary: 'annuityTotal', field: PolicyTransactionKeys.annuity },
    { summary: 'countTotal', field: PolicyTransactionKeys.policies },
    { summary: 'premiumTotal', field: PolicyTransactionKeys.weightedPrem },
    //
    { summary: 'outsideTotal', field: 'outsideTotal' },
  ];

  constructor(
    protected cloudFunctionsService: CloudFunctionsService,
    private policyTransactionSummariesElasticSearchService: PolicyTransactionSummariesElasticSearchService,
  ) {
    super(`${SupportedCollections.policyTransactions}-*`, SupportedCollections.policyTransactions);

    this.defaultSorting = [{ [PolicyTransactionKeys.transDate]: 'asc' }, '_doc'];

    this.runtimeMappings$ = combineLatest({
      [PolicyTransactionKeys.agentDisplayName]:
        this.policyTransactionSummariesElasticSearchService.agentIdToNameMap$.pipe(
          map(params => {
            const source = `
            def agentId = field('agent_id').get('');
            def fileName = field('agent_name').get('');
            if (params.containsKey(agentId)) {
              emit(params[agentId]);
            } else {
              emit(fileName)
            }
          `.replace(/\n|\s{2,}/gm, '');
            return { source, params };
          }),
        ),
    });

    this.resolveRuntimeMappings();
  }

  getDataSource = (options?: LoadOptions) => {
    return new DataSource({
      paginate: true,
      pageSize: 50,
      store: this.getStore(options),
    });
  };

  getStore = (options?: LoadOptions) => {
    const ids = {};
    const store = new CustomStore({
      key: PolicyTransactionKeys.agentId,
      byKey: id => this.getById(id),
      load: async loadOptions => {
        const filter = [options?.filter].filter(Boolean);
        const group = loadOptions?.group;

        let groupSelector = group?.[0]?.selector;

        if (groupSelector?.context?.column?.name) {
          groupSelector = groupSelector?.context?.column?.name;
          group[0].selector = groupSelector;
        }

        if (loadOptions.filter) {
          filter?.length && filter.push(DxFilterOperators.and);
          filter.push(loadOptions.filter);
        }

        loadOptions.filter = filter;

        set(loadOptions, ['userData', 'treeGroups'], true);

        const isLoadingAll = (loadOptions as any).isLoadingAll;
        if (isLoadingAll) {
          set(loadOptions, ['userData', 'isLoadingAll'], true);
        }
        // set(loadOptions, ['userData', 'treeGroups'], true);

        const skip = loadOptions?.skip;

        if (groupSelector && !!skip) {
          const searchAfter = ids?.[groupSelector]?.[skip - 1];

          searchAfter &&
            set(loadOptions, ['userData', 'searchAfter'], {
              [groupSelector]: searchAfter,
            });
        }

        const response = await this.getFromElastic(loadOptions);

        if (groupSelector && !ids?.[groupSelector]) {
          ids[groupSelector] = [];
        }

        if (groupSelector) {
          const keys = response?.data?.map(item => item?.key);

          const itemsToRemove = ids?.[groupSelector]?.length - (skip ?? 0);
          ids?.[groupSelector].splice(skip ?? 0, itemsToRemove, ...keys);
        }

        return response;
      },
    });

    return store;
  };

  getSalesByCarrier = async (payload: ProductionStatsPayload) => {
    const query: QueryDslQueryContainer = this.getStatsQuery(payload);
    const summaryAggregations = {};

    this.histogramSummaries.forEach(({ summary, field }) => {
      summaryAggregations[summary] = { sum: { field } };
    });
    const compositeAggregation: AggregationsCompositeAggregation = {
      size: 20,
      sources: [
        {
          [PolicyTransactionKeys.carrierName]: {
            terms: {
              field: PolicyTransactionKeys.carrierName,
            },
          },
        },
      ],
    };
    const salesByCarrierAggregation: Record<string, AggregationsAggregationContainer> = {
      salesByCarrier: {
        composite: compositeAggregation,
        aggregations: summaryAggregations,
      },
      count: {
        cardinality: {
          field: PolicyTransactionKeys.carrierName,
        },
      },
    };
    const searchRequest: SearchRequest = {
      index: this.defaultIndex, // Specify your index name here
      size: 0, // No documents in the response, just aggregations
      query: query,
      aggregations: salesByCarrierAggregation,
    };

    const salesByCarrier = [];

    const getSales = async () => {
      const response = await this.cloudFunctionsService.searchWithElastic(searchRequest);

      const aggregations: any = response?.data?.aggregations;
      const groupCount = aggregations?.count?.value ?? null;
      const salesByCarrierResponse = aggregations?.salesByCarrier;
      const lastHit = salesByCarrierResponse?.after_key;
      const buckets = salesByCarrierResponse?.buckets;

      if (!buckets?.length) {
        return;
      }

      salesByCarrier.push(...this.normalizeSalesSummaries(buckets));

      if (salesByCarrier?.length === groupCount) {
        return;
      }

      compositeAggregation.after = lastHit;

      await getSales();
    };

    await getSales();

    return salesByCarrier;
  };

  getReportColumns = async (calendarInterval: 'year' | 'quarter' = 'year'): Promise<string[]> => {
    const revenueAggregation: Record<string, AggregationsAggregationContainer> = {
      histogram: {
        date_histogram: {
          field: PolicyTransactionKeys.transDate,
          calendar_interval: calendarInterval, // Group by month
          min_doc_count: 0,
        },
      },
    };
    const searchRequest: SearchRequest = {
      index: this.defaultIndex, // Specify your index name here
      size: 0, // No documents in the response, just aggregations
      aggregations: revenueAggregation,
    };
    const response = await this.cloudFunctionsService.searchWithElastic(searchRequest);

    return (response?.data?.aggregations?.histogram as any)?.buckets?.map(bucket => bucket?.key_as_string);
  };

  getAgentProductionByYears = async (agentsIds: string[], calendarInterval: 'year' | 'quarter' = 'year') => {
    const summaryAggregations = {};

    [
      { summary: 'countTotal', field: PolicyTransactionKeys.policies },
      { summary: 'premiumTotal', field: PolicyTransactionKeys.weightedPrem },
    ].forEach(({ summary, field }) => {
      summaryAggregations[summary] = { sum: { field } };
    });

    const revenueAggregation: Record<string, AggregationsAggregationContainer> = {
      histogram: {
        date_histogram: {
          field: PolicyTransactionKeys.transDate,
          calendar_interval: calendarInterval, // Group by month
          min_doc_count: 0,
        },
        aggregations: summaryAggregations,
      },
    };
    const shouldAgentsQuery: QueryDslQueryContainer[] = agentsIds.map(agentId => {
      return { match: { [PolicyTransactionKeys.agentId]: agentId } };
    });
    const searchRequest: SearchRequest = {
      index: this.defaultIndex, // Specify your index name here
      size: 0, // No documents in the response, just aggregations
      query: { bool: { should: shouldAgentsQuery } },
      aggregations: {
        groups: {
          composite: {
            size: 500,
            sources: [
              {
                [PolicyTransactionKeys.agentId]: {
                  terms: {
                    field: PolicyTransactionKeys.agentId,
                    missing_bucket: true,
                    missing_order: 'default',
                  },
                },
              },
            ],
          },
          aggregations: revenueAggregation,
        },
        count: {
          cardinality: {
            field: PolicyTransactionKeys.agentId,
          },
        },
      },
    };

    let searchAfter;
    const data = [];
    const getItems = async () => {
      if (searchAfter) {
        searchRequest.aggregations.groups.composite.after = searchAfter;
      }

      const response = await this.cloudFunctionsService.searchWithElastic(searchRequest);
      const aggregations: any = response?.data?.aggregations;
      const groupCount = aggregations?.count?.value ?? null;
      const groups = aggregations?.groups;
      const lastHit = groups?.after_key;
      const buckets = groups?.buckets;

      if (!buckets?.length) {
        return;
      }
      searchAfter = lastHit;

      data.push(...buckets);

      if (!lastHit || data?.length === groupCount) {
        return;
      }

      return getItems();
    };
    await getItems();

    const map = new Map();

    data.forEach(aggregation => {
      const agentId = aggregation?.key?.[PolicyTransactionKeys.agentId];
      const histogram = (aggregation?.histogram as AggregationsHistogramAggregate)?.buckets;

      if (!Array.isArray(histogram)) {
        return;
      }
      const stats = {};

      histogram?.forEach(bucket => {
        const key = bucket?.key_as_string;
        const bucketAggregation: any = bucket?.['premiumTotal'];
        const value = bucketAggregation?.value;

        stats[key] = value;
      });

      map.set(agentId, stats);
    });

    return map;
  };

  getStats = async (payload: ProductionStatsPayload) => {
    const year = payload?.year;
    const range = this.getYearRange(year);
    const query: QueryDslQueryContainer = this.getStatsQuery(payload);
    const summaryAggregations = {};

    this.histogramSummaries.forEach(({ summary, field }) => {
      summaryAggregations[summary] = { sum: { field } };
    });

    const revenueAggregation: Record<string, AggregationsAggregationContainer> = {
      monthHistogram: {
        date_histogram: {
          field: PolicyTransactionKeys.transDate,
          calendar_interval: 'month', // Group by month
          min_doc_count: 0,
          extended_bounds: {
            min: range?.gte, // Ensure the histogram starts from January
            max: range?.lte, // Ensure it covers up to December
          },
        },
        aggregations: summaryAggregations,
      },

      countTotal: {
        sum: {
          field: PolicyTransactionKeys.policies,
        },
      },
      totalWeightedPrem: {
        sum: {
          field: PolicyTransactionKeys.weightedPrem,
        },
      },
    };

    const searchRequest: SearchRequest = {
      index: this.defaultIndex, // Specify your index name here
      size: 0, // No documents in the response, just aggregations
      query: query,
      aggregations: revenueAggregation,
    };

    const response = await this.cloudFunctionsService.searchWithElastic(searchRequest);

    const aggregations = response?.data?.aggregations;
    const monthHistogram = (aggregations?.monthHistogram as AggregationsHistogramAggregate)?.buckets;
    const monthStats = this.normalizeHistogram(monthHistogram as any[], 'LLL');
    const countTotal = (aggregations?.countTotal as AggregationsSumAggregate)?.value;
    const totalWeightedPrem = (aggregations?.totalWeightedPrem as AggregationsSumAggregate)?.value;
    const avgWeightedPrem =
      countTotal && totalWeightedPrem ? totalWeightedPrem / countTotal : (totalWeightedPrem ?? null);

    return {
      year,
      monthStats,
      countTotal,
      totalWeightedPrem,
      avgWeightedPrem,
    };
  };

  private normalizeSalesSummaries = (buckets: any[]) => {
    return buckets?.map(bucket => {
      const name = bucket?.key?.[PolicyTransactionKeys.carrierName];
      const item = {
        name,
      };

      this.histogramSummaries.forEach(({ summary }) => {
        const value = bucket?.[summary]?.value;
        item[summary] = value ?? null;
      });

      return item;
    });
  };

  private normalizeHistogram = (buckets: any[], labelFormat?: string) => {
    return buckets?.map(bucket => {
      const label = labelFormat ? format(bucket?.key_as_string, labelFormat) : bucket?.key_as_string;
      const item = {
        label,
      };

      this.histogramSummaries.forEach(({ summary }) => {
        if (!(summary in bucket)) {
          return;
        }
        const value = bucket?.[summary]?.value;
        item[summary] = value ?? null;
      });

      return item;
    });
  };

  private getYearRange = (year: number) => {
    const gte = `${year}/01/01`;
    const lte = `${year}/12/31`;

    return { gte, lte };
  };

  private getStatsQuery = ({ year, agencyIds, agentId, mgaId }: ProductionStatsPayload) => {
    const should: QueryDslQueryContainer[] = [];
    const query: QueryDslQueryContainer = {
      bool: {
        must: [
          {
            range: {
              [PolicyTransactionKeys.transDate]: this.getYearRange(year),
            },
          },
          {
            bool: {
              should,
            },
          },
        ],
      },
    };

    if (!!agentId) {
      should.push({ match: { [PolicyTransactionKeys.agentId]: agentId } });
    }
    if (!!mgaId) {
      should.push({ match: { [PolicyTransactionKeys.mgaId]: mgaId } });
    }
    if (Array.isArray(agencyIds)) {
      agencyIds.forEach(agencyId => {
        should.push({ match: { [PolicyTransactionKeys.mgaId]: agencyId } });
      });
    }

    return query;
  };
}
